import { Action } from '@ngrx/store';
import {
    CompanyProductDefinition,
    FollowupAction,
    FollowupGoal,
    FollowupNow,
    GroupResultInfo,
    Module,
    Recommendation,
    RecommendationCategory,
    RecommendationSubCategory,
} from 'app/shared/models';

export enum Type {
    START_FOLLOWUP = '[FOLLOWUP_WIZARD] START_FOLLOWUP',
    SELECT_PRIORITIES = '[FOLLOWUP_WIZARD] SELECT_PRIORITIES',
    SELECTED_RECOMMENDATION = '[FOLLOWUP_WIZARD] SELECTED_RECOMMENDATION',
    SELECTED_RECOMMENDATION_CATEGORY = '[FOLLOWUP_WIZARD] SELECTED_RECOMMENDATION CATEGORY',
    SELECTED_RECOMMENDATION_SUBCATEGORY = '[FOLLOWUP_WIZARD] SELECTED_RECOMMENDATION SUBCATEGORY',
    ADD_NOW = '[FOLLOWUP_WIZARD] ADD_NOW',
    EDIT_NOW = '[FOLLOWUP_WIZARD] EDIT_NOW',
    REMOVE_NOW = '[FOLLOWUP_WIZARD] REMOVE_NOW',
    ADD_GOAL = '[FOLLOWUP_WIZARD] ADD_GOAL',
    EDIT_GOAL = '[FOLLOWUP_WIZARD] EDIT_GOAL',
    REMOVE_GOAL = '[FOLLOWUP_WIZARD] REMOVE_GOAL',
    ADD_ACTION = '[FOLLOWUP_WIZARD] ADD_ACTION',
    EDIT_ACTION = '[FOLLOWUP_WIZARD] EDIT_ACTION',
    REMOVE_ACTION = '[FOLLOWUP_WIZARD] REMOVE_ACTION',
    ADD_SUGGESTIONS = '[FOLLOWUP_WIZARD] ADD_SUGGESTIONS',
    UNLOAD_FOLLOWUP_WIZARD = '[FOLLOWUP_WIZARD] UNLOAD_FOLLOWUP_WIZARD',
    SET_BYPASSGUARD = '[FOLLOWUP_WIZARD] SET_BYPASSGUARD'
}
export class StartFollowup implements Action {
    readonly type = Type.START_FOLLOWUP;
    constructor(public payload: CompanyProductDefinition,
        public groupResultInfo: GroupResultInfo,
        public recommendations: Recommendation[],
        public groupId?: number,
        public productId?: number) { }
}
export class SelectPriorities implements Action {
    readonly type = Type.SELECT_PRIORITIES;
    constructor(public payload: Module) { }
}
export class SelectedRecommendation implements Action {
    readonly type = Type.SELECTED_RECOMMENDATION;
    constructor(public payload: Recommendation) { }
}
export class SelectedRecommendationCategory implements Action {
    readonly type = Type.SELECTED_RECOMMENDATION_CATEGORY;
    constructor(public payload: RecommendationCategory) { }
}
export class SelectedRecommendationSubCategory implements Action {
    readonly type = Type.SELECTED_RECOMMENDATION_SUBCATEGORY;
    constructor(public payload: RecommendationSubCategory) { }
}
export class AddNow implements Action {
    readonly type = Type.ADD_NOW;
    constructor(public payload: FollowupNow) { }
}

export class EditNow implements Action {
    readonly type = Type.EDIT_NOW;
    constructor(public payload: FollowupNow) { }
}
export class RemoveNow implements Action {
    readonly type = Type.REMOVE_NOW;
    constructor(public payload: FollowupNow) { }
}
export class AddGoal implements Action {
    readonly type = Type.ADD_GOAL;
    constructor(public payload: FollowupGoal) { }
}
export class EditGoal implements Action {
    readonly type = Type.EDIT_GOAL;
    constructor(public payload: FollowupGoal) { }
}
export class RemoveGoal implements Action {
    readonly type = Type.REMOVE_GOAL;
    constructor(public payload: FollowupGoal) { }
}
export class AddAction implements Action {
    readonly type = Type.ADD_ACTION;
    constructor(public payload: FollowupAction) { }
}
export class EditAction implements Action {
    readonly type = Type.EDIT_ACTION;
    constructor(public payload: FollowupAction) { }
}
export class RemoveAction implements Action {
    readonly type = Type.REMOVE_ACTION;
    constructor(public payload: FollowupAction) { }
}

export class AddSuggestions implements  Action {
  readonly type = Type.ADD_SUGGESTIONS;
  constructor(public payload: string) {}
}

export class UnloadFollowupWizard implements Action {
    readonly type = Type.UNLOAD_FOLLOWUP_WIZARD;
}

export class SetByPassGuard implements Action {
  readonly type = Type.SET_BYPASSGUARD;
  constructor(public payload: boolean) {}
}


export type Actions =
    StartFollowup |
    SelectPriorities |
    SelectedRecommendation |
    SelectedRecommendationCategory |
    SelectedRecommendationSubCategory |
    AddNow |
    EditNow |
    RemoveNow |
    AddGoal |
    EditGoal |
    RemoveGoal |
    AddAction |
    EditAction |
    RemoveAction |
    AddSuggestions |
    UnloadFollowupWizard |
    SetByPassGuard;
