import { Action } from '@ngrx/store';
import { CompanyProductDefinition, Product, WizardContexts, WizardTypes } from 'app/shared/models';
import { FollowupWizardContext, SurveyWizardContext } from 'app/shared/models/product-wizard.model';

export enum Type {
  SET_WIZARD_STATE = '[PRODUCT_WIZARD] SET_WIZARD_STATE',
  SELECT_PRODUCT_DEFINITION = '[PRODUCT_WIZARD] SELECT_PRODUCT_DEFINITION',
  SELECT_PRODUCT_DEFINITION_SUCCESS = '[PRODUCT_WIZARD] SELECT_PRODUCT_DEFINITION_SUCCESS',
  SET_PAGE = '[PRODUCT_WIZARD] SET_PAGE',
  CREATE_PRODUCT = '[PRODUCT_WIZARD] CREATE_PRODUCT',
  DELETE_PRODUCT = '[PRODUCT_WIZARD] DELETE_PRODUCT',
  PRODUCT_CREATED = '[PRODUCT_WIZARD] PRODUCT_CREATED',
  CREATE_DRAFT = '[PRODUCT_WIZARD] CREATE_DRAFT',
  SETUP_DRAFT = '[PRODUCT_WIZARD] SETUP_DRAFT',
  SAVE_DRAFT = '[PRODUCT_WIZARD] SAVE_DRAFT',
  DRAFT_SAVED = '[PRODUCT_WIZARD] DRAFT_SAVED',
  LOAD_DRAFT = '[PRODUCT_WIZARD] LOAD_DRAFT',
  SAVE_DRAFT_GO_BACK = '[PRODUCT_WIZARD] SAVE_DRAFT_GO_BACK',
  SET_CHANGED = '[SURVEY_WIZARD] SET_CHANGED',
  SET_CHANGED_SUCCESS = '[SURVEY_WIZARD] SET_CHANGED_SUCCESS',
  CREATE_FROM_SURVEY = '[SURVEY_WIZARD] CREATE_FROM_SURVEY',
  CREATE_FROM_SURVEY_SUCCESS = '[SURVEY_WIZARD] CREATE_FROM_SURVEY_SUCCESS'
}

export class SetWizardState implements Action {
  readonly type = Type.SET_WIZARD_STATE;
  constructor(public payload: WizardContexts) { }
}

export class SelectProductDefinition implements Action {
  readonly type = Type.SELECT_PRODUCT_DEFINITION;
  constructor(public payload: CompanyProductDefinition, public wizardType: WizardTypes) { }
}

export class SelectProductDefinitionSuccess implements Action {
  readonly type = Type.SELECT_PRODUCT_DEFINITION_SUCCESS;
  constructor(public payload: CompanyProductDefinition, public wizardType: WizardTypes) { }
}
export class CreateProduct implements Action {
  readonly type = Type.CREATE_PRODUCT;
  constructor(public payload: WizardContexts) { }
}

export class DeleteProduct implements Action {
  readonly type = Type.DELETE_PRODUCT;
  constructor(public productId: number, public wizardType: WizardTypes) { }
}

export class ProductCreated implements Action {
  readonly type = Type.PRODUCT_CREATED;
  constructor(public payload: number, public wizardType: WizardTypes) { }
}

export class CreateDraft implements Action {
  readonly type = Type.CREATE_DRAFT;
  constructor(public payload: SurveyWizardContext | FollowupWizardContext) { }
}
export class SetupDraft implements Action {
  readonly type = Type.SETUP_DRAFT;
  constructor(public payload: CompanyProductDefinition, public wizardType: WizardTypes) { }
}
export class SaveDraft implements Action {
  readonly type = Type.SAVE_DRAFT;
  constructor(public payload: WizardContexts, public showMessage = false) { }
}

export class SaveDraftAndCancel implements Action {
  readonly type = Type.SAVE_DRAFT_GO_BACK;
  constructor(public payload: WizardContexts, public showMessage = false) { }
}

export class DraftSaved implements Action {
  readonly type = Type.DRAFT_SAVED;
  constructor(public payload: { productId: number, currentVersion: string }, public wizardType: WizardTypes, public showMessage = false) { }
}

export class LoadDraft implements Action {
  readonly type = Type.LOAD_DRAFT;
  constructor(public payload: number | Product, public wizardType: WizardTypes, public wasDeactivated = false, public forceStep = -1) { }
}

export class SetPage implements Action {
  readonly type = Type.SET_PAGE;
  constructor(public payload: number, public wizardType: WizardTypes) { }
}

export class SetChanged implements Action {
  readonly type = Type.SET_CHANGED;
  constructor(public payload: { changed: boolean, action?: Action }) { }
}

export class SetChangedSuccess implements Action {
  readonly type = Type.SET_CHANGED_SUCCESS;
  constructor(public payload: { changed: boolean, action?: Action }) { }
}

export class CreateFromSurvey implements Action {
  readonly type = Type.CREATE_FROM_SURVEY;
  constructor(public payload: number) { }
}

export class CreateFromSurveySuccess implements Action {
  readonly type = Type.CREATE_FROM_SURVEY_SUCCESS;
  constructor(public payload: WizardContexts) { }
}

export type Actions = SetWizardState |
  SelectProductDefinition |
  SelectProductDefinitionSuccess |
  CreateProduct |
  ProductCreated |
  CreateDraft |
  SetupDraft |
  SaveDraft |
  DraftSaved |
  LoadDraft |
  SetPage |
  SaveDraftAndCancel |
  SetChanged |
  SetChangedSuccess |
  CreateFromSurvey |
  CreateFromSurveySuccess;
